import React from "react"
import { createGlobalStyle, ThemeProvider } from "styled-components"
import Theme from "./src/themes/theme"
import { Helmet } from "react-helmet"

const GlobalStyles = createGlobalStyle`
    * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        font-family: ${props => props.theme.font.main};
    }
    html {
        overflow-x: hidden;
    }
    body, html {
        height: 100%;
        background-color: ${props => props.theme.colors.background};
        overscroll-behavior: none;
    }
    html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
        margin: 0px;
        padding: 0px;
        border: 0px none;
        vertical-align: baseline;
        scroll-behavior: unset;
    }
      
    *, ::before, ::after {
        box-sizing: border-box;
    }
    ::selection {
        background: ${props => props.theme.colors.foreground};
        color: ${props => props.theme.colors.background};
    }
    body {
        width: 100vw;
        min-height: 100vh;
        margin-left: auto;
        margin-right: auto;
        // overflow-x: hidden;
    }
    
    a {
        color: inherit;
        text-decoration: none;
    }
    
    a:hover {
        text-decoration: none;
    }
    strong {
        font-weight: 500;
    }
    
    button {
        background: none;
        text-decoration: none;
        border: none;
    }
    
    button:focus {
        outline: none;
        box-shadow: none;
    }

    .modalContent {
        position: absolute;
        inset: 0;
        width: 100%;
        top: 0;
        bottom: 0;
        margin: 0 auto;
        // border-radius: ${props => props.theme.spacings.large};
        border-top: 1px solid ${props => props.theme.colors.flash};
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        outline: none;
        padding: 0px;
        background-color: ${props => props.theme.colors.background};
    }

    .modalOverlayContent {
        position: fixed;
        z-index: 10;
        inset: 0;
        width: 100vw;
        top: ${props => props.theme.spacings.large};
        left: 0;
        right: 0;
        bottom: 0;
        margin: 0 auto;
        background-color: transparent;
        transform: translateY(100%);
        animation: slide-in 0.5s forwards;
        -webkit-animation: slide-in 0.5s forwards;
    }

    @keyframes slide-in {
        100% { transform: translateY(0%); }
    }
    
    @-webkit-keyframes slide-in {
        100% { -webkit-transform: translateY(0%); }
    }

    details[open] > nav > ul > * {
        animation: slide-up .3s ease-in-out;
        opacity: 1;
    }

    @keyframes slide-up {
        0%    {opacity: 0; transform: translateY(200%)}
        100%  {opacity: 1; transform: translateY(0%)}
      }
`

export const wrapRootElement = ({ element }) => (
    <ThemeProvider theme={Theme}>
        <Helmet>
            <meta charSet="utf-8" />
            <title>m2ecn</title>
            <link rel="preconnect" href="https://fonts.googleapis.com"></link>
            <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin></link>
            <link href="https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap" rel="stylesheet"></link>
            <link href="https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap&text=%E2%86%90%E2%86%92" rel="stylesheet"></link>
        </Helmet>
        <GlobalStyles />
        {element}
    </ThemeProvider>
)