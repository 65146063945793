export default {
    font: {
        main: "'IBM Plex Sans', sans-serif",
    },
    colors: {
        foreground: "#505050",
        background: "#f6f6f6",
        secondary: "#6f6f6f",
        grey1: "#dddddd",
        grey2: "#e8e8e8",
        grey3: "#f0f0f0",
        flash: "#FBD060"
    },
    breakpoints: {
        mobile: "only screen and (max-width: 767px)",
        desktop: "only screen and (min-width: 768px)",
    },
    spacings: {
        xxxSmall: "6px",
        xxSmall: "8px",
        xSmall: "16px",
        small: "32px",
        medium: "64px",
        large: "96px",
        xLarge: "128px",
        xxLarge: "256px",
        largest: "512px",
        section: "8vw",
    }
}